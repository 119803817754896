// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/BebasNeue-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Quicksand-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Quicksand-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Quicksand-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/Quicksand-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Bebas Neue\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Quicksand\";\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"Quicksand\";\n  font-weight: 600;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: \"Quicksand\";\n  font-weight: 800;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n  font-family: \"Quicksand\";\n  font-weight: 900;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n*,\nbody {\n  font-family: \"Quicksand\", sans-serif;\n}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,4CAAA;AACF;AAEA;EACE,wBAAA;EACA,gBAAA;EACA,4CAAA;AAAF;AAGA;EACE,wBAAA;EACA,gBAAA;EACA,4CAAA;AADF;AAIA;EACE,wBAAA;EACA,gBAAA;EACA,4CAAA;AAFF;AAKA;EACE,wBAAA;EACA,gBAAA;EACA,4CAAA;AAHF;AAMA;;EAEE,oCAAA;AAJF","sourcesContent":["@font-face {\n  font-family: 'Bebas Neue';\n  src: url('assets/fonts/BebasNeue-Regular.ttf');\n}\n\n@font-face {\n  font-family: 'Quicksand';\n  font-weight: 400;\n  src: url('assets/fonts/Quicksand-Regular.ttf');\n}\n\n@font-face {\n  font-family: 'Quicksand';\n  font-weight: 600;\n  src: url('assets/fonts/Quicksand-Medium.ttf');\n}\n\n@font-face {\n  font-family: 'Quicksand';\n  font-weight: 800;\n  src: url('assets/fonts/Quicksand-SemiBold.ttf');\n}\n\n@font-face {\n  font-family: 'Quicksand';\n  font-weight: 900;\n  src: url('assets/fonts/Quicksand-Bold.ttf');\n}\n\n*,\nbody {\n  font-family: 'Quicksand', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
